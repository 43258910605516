import { Check } from "react-feather";
import Button from "../../components/Button";
import ModalContent from "../modalcontent/ModalContent";

function DisclaimerMessage({ handleConfirmAcknowledgement }: any) {
	return (
		<ModalContent headerText="Acknowledgement of Customer Contact Info">
			<div className="agreement-content">
				<section className="exp">
					<span>
						As a way to comply with Contact Tracing guidelines set
						by the Government of Ontario, when dining in with us,
						customers are asked to provide contact information in
						the event that a case or outbreak occurs. Your
						information will stay with us for 30 calendar days and
						will be destroyed after.
					</span>
					<span>
						We are doing our part to protect everyone who dines in
						our premises and reduce the overall spread of COVID-19.
					</span>
					<span>
						If you do agree to dine with us today, please scan the table
						QR code again with this same smartphone while paying for your
						meal, so you can exit your dining session.
					</span>
					<span>
						Thank you very much for your cooperation. We hope you
						enjoy your meal today!
					</span>
				</section>

				<div className="divider"></div>

				<div className="agreement-sentence">
					By clicking on the Confirmation button below, you agree to
					provide customer contact information.
				</div>
				<Button
					label="Confirm"
					icon={<Check />}
					onClick={handleConfirmAcknowledgement}
				/>
			</div>
		</ModalContent>
	);
}

export default DisclaimerMessage;
