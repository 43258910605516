import React, { useEffect, useState } from "react";
import "./App.scss";
import { Footer } from "./features/footer/Footer";
import { Header } from "./features/header/Header";
import { SignIn } from "./features/signin/SignIn";
import { SignOut } from "./features/signout/SignOut";
import { useLocation } from "react-router";
import { isTableOccupied } from "./app/api";
import ModalContainer from "./components/Modal/ModalContainer";
import Modal from "./components/Modal/Modal";
import ErrorMessage from "./features/ErrorMessage";

function App() {
	const [table, setTable] = useState(null);
	const [valid, setValid] = useState({
		isValidToken: false,
		isOccupied: false
	});
	const [tableNumber, setTableNumber] = useState<string | null>('');
	const [showModal, setShowModal] = useState(false);
	const [errormsg, setErrorMsg] = useState('');
	const [justSignedOut, setSignedOut] = useState(false);

	const search = useLocation().search;

	const _isTableOccupied = async () => {
		const tn = new URLSearchParams(search).get("tablenumber");
		const n = tn;
		setTableNumber(n);
		const a = await isTableOccupied(n);
		console.log(a);
		setValid({
			isOccupied: a.isOccupied,
			isValidToken: a.isValidToken
		});

	};

	useEffect(() => {
		_isTableOccupied();
	}, []);

	const closeModal = () => {
		setShowModal(false);
	};

	const closeModalAndReload = () => {
		setShowModal(false);
		_isTableOccupied();
	}

	const handleAuth = () => {
		_isTableOccupied();
	};

	const handleSignOut = () => {

		setSignedOut(true);
		setTimeout(() => {
			window.location.replace('https://genjinorthyork.com');
		}, 7000);
	}

	const showForm = () => {

		// invalid token, but occupied
		if (!valid.isValidToken && valid.isOccupied) {
			return (
				<ModalContainer>
					<Modal showCloseButton={false}>
						<ErrorMessage
							handleConfirmAcknowledgement={closeModalAndReload}
							message="This table is occupied but your device was not validated. Please let your Server know about this issue."
						/>
					</Modal>
				</ModalContainer>
			);

		// valid token, but already signed out
		} else if (valid.isValidToken && !valid.isOccupied) {

			localStorage.removeItem("genji_token");

			return (
				<>
					<ModalContainer>
						<Modal
							showCloseButton={false}
						>
							<ErrorMessage
								handleConfirmAcknowledgement={
									closeModalAndReload
								}
								message="Your token is still valid but you already left your table. We will now remove your token from your device."
							/>
						</Modal>
					</ModalContainer>
				</>
			);

		// valid token and signed in, ask to sign out
		} else if (valid.isValidToken && valid.isOccupied) {
			return <SignOut onSignout={handleSignOut} tableNum={tableNumber} />;

		// no valid token and not yet occupied, sign in now
		} else if (!valid.isValidToken && !valid.isOccupied) {
			return <SignIn onSignIn={handleAuth} tableNum={tableNumber} />;
		}

		// return <SignIn tableNumber={tableNumber} />;

		return null;
	}

	const showJustSignedOut = () => {
		return (
				<ModalContainer>
					<Modal
						onClick={closeModal}
						showCloseButton={false}
					>
						<ErrorMessage
							showConfirm={false}
							title="Signed Out"
							message="You have been signed out successfully. Your information with us will automatically be deleted in 30 calendar days. Now redirecting back to Genji Sushi North York website. Have a great day!" />
					</Modal>
				</ModalContainer>
		);
	}

	return (
		<div className="App">
			<Header />
			{!justSignedOut ? showForm() : showJustSignedOut()}
			<Footer />
		</div>
	);
}

export default App;
