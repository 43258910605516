import "./Footer.scss";

export function Footer() {
	return (
		<footer>
			<div>
				Copyright 2021 by Genji Sushi North York. All Rights Reserved.
			</div>
		</footer>
	);
}
