import React, { Fragment, useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import './Modal.scss';

function Modal({ children, delay, onClick, identifier, showCloseButton = true }: any) {
	const [start, setStart] = useState(false);
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const ref = useRef<any>(null);

	useEffect(() => {
		const refcurrent = ref.current;

		if(refcurrent) {
			setHeight(refcurrent.clientHeight || 0);
			setWidth(refcurrent.clientWidth || 0);

			setTimeout(() => {
				setStart(true);
			}, delay * 1500);
		}
	});

	const handleClick = (e: any) => {
		e.stopPropagation();
		onClick(identifier);
	};

	return (
		<>
			<div className={`modal ${start ? "show" : ""}`} ref={ref}>
				<div className="modal-content">{children}</div>
				{
					showCloseButton &&
					<button className="closebtn" onClick={handleClick}>
						<X />
					</button>
				}
				<svg
					className="modal-svg"
					xmlns="http://www.w3.org/2000/svg"
					width="100%"
					height="100%"
					preserveAspectRatio="none"
				>
					<rect
						x="0"
						y="0"
						fill="none"
						width={width}
						height={height}
						rx="3"
						ry="3"
					></rect>
				</svg>
			</div>
		</>
	);
}

export default Modal;
