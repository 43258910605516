import React, { Fragment, useEffect, useRef, useState } from "react";
import "./ModalContainer.scss";

function ModalContainer({ children, onClearAll }: any) {

	const handleClear = (e: any) => {
		e.stopPropagation();
		onClearAll && onClearAll();
	}

	return (
		<>
			<div id="modal-container" className="six">
				<div onClick={(e) => handleClear(e) } className="modal-background">
					{children}
				</div>
			</div>
		</>
	);
}

export default ModalContainer;
