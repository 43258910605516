import GenjiLogo from '../../assets/genji-justlogo.png';
import './Header.scss';

export function Header() {
    return (
		<header>
			<img src={GenjiLogo} alt="logo" />
		</header>
	);
}
