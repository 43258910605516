const queryURL = process.env.REACT_APP_API_URL;
// const queryURL = 'http://localhost:2020/';

export const isTableOccupied = async (tableNumber?: string | null): Promise<any> => {
	// get token
	const token = localStorage.getItem('genji_token');

	const a = await fetch(`${queryURL}isTableOccupied/${tableNumber}`, {
		method: 'get',
		headers: new Headers({
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'application/json'
		})
	});

	return await a.json();
};

export const signIn = async (data?: any): Promise<any> => {

	const a = await fetch(`${queryURL}customerTable/`, {
		method: "post",
		body: JSON.stringify(data),
		headers: new Headers({
			"Content-Type": "application/json",
		}),
	});

	const resp = await a.json();	// will receive a token
	let success = false;

	if(resp.token) {
		localStorage.setItem("genji_token", resp.token);
		success = true;
	}

	return success;
};

export const signOut = async (data?: any): Promise<any> => {

	const token = localStorage.getItem("genji_token");

	const a = await fetch(`${queryURL}customerTable/`, {
		method: "put",
		body: JSON.stringify(data),
		headers: new Headers({
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}),
	});

	const resp = await a.json();
	let success = false;

	if (resp.signedOut === true) {
		localStorage.removeItem("genji_token");
		success = true;
	}

	return success;
};
