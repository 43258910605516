import { useEffect, useState } from "react";
import { Check, Send } from "react-feather";
import { signOut } from "../../app/api";
import Button from "../../components/Button";
import Form from "../../components/Form";
import "./SignOut.scss";

export function SignOut({ tableNum, onSignout }: any) {
	const [submitted, setSubmitted] = useState(false);
	const [tableNumber, setTableNumber] = useState(tableNum);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTableNumber(tableNum);
	}, [tableNum]);

	const handleSubmit = async () => {

		setLoading(true);
		const data = {
			tableNumber: tableNumber,
			submitted: false
		};

		setSubmitted(true);
		data.submitted = true;
		await signOut(data);
		onSignout && onSignout();

		setLoading(false);
	};

	return (
		<>
			<Form
				className="sign-out-form"
				header="Genji Sushi North York COVID-19 Contact Tracing"
			>
				<h2>Ready to Leave?</h2>

				<h4 className="curr-table">
					You are sitting at Table # <span>{tableNumber}</span>
				</h4>

				<div className="controls">
					<Button
						label="Leave"
						icon={submitted ? <Check /> : <Send />}
						disabled={loading}
						onClick={handleSubmit}
					></Button>
				</div>
			</Form>
		</>
	);
}
