function TextInput({ label, onChange }: any) {

	const handleChange = (e: any) => {
		const val = e.target.value;
		onChange && onChange(val);
	}

	return (
		<>
			<div className="text input-group">
				<div className="input-label">{label}</div>
				<div className="input">
					<input type="text" onChange={handleChange} />
				</div>
			</div>
		</>
	);
}

export default TextInput;
