import FormHeader from "./FormHeader";
import './Form.scss';

function Form(props: any) {
	return (
		<div className={`form ${props.className}`} style={props.style}>
			<FormHeader text={props.header} />
			<div className="messages"></div>
			<form>
				{props.children}
			</form>
		</div>
	);
}

export default Form;
