import React, { useEffect, useState } from "react";
import MaskedInput from "react-maskedinput";

function NumberInput({ label, value, onChange }: any) {
	const [localValue, setLocalValue] = useState(value);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const change = (inp: any) => {
		setLocalValue(inp.target.value);
		onChange && onChange(inp.target.value);
	};
	return (
		<>
			<div className="number input-group">
				<div className="input-label">{label}</div>
				<div className="input">
					<MaskedInput
						mask="1"
						placeholder="#"
						name="card"
						onChange={change}
					/>
				</div>
			</div>
		</>
	);
}

export default NumberInput;
