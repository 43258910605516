import './Button.scss';

function Button({ label, icon, disabled = false, onClick }: any) {

	const handleClick = (evt: any) => {
		evt.preventDefault();
		onClick && onClick();
	}
	return (
		<button disabled={disabled} className="button" onClick={handleClick}>
			<span className="button-icon">{icon}</span>
			<span className="button-label">{label}</span>
		</button>
	);
}

export default Button;
