import React from 'react';
import ReactInputMask from 'react-input-mask';

export default function PhoneInput({ label, value, onChange }: any) {

	const handleChange = (evt: any) => {
		console.log(evt);
		const val = evt.target.value;
		onChange && onChange(val);
	}
	return (
		<>
			<div className="phone input-group">
				<div className="input-label">{label}</div>
				<div className="input">
					<ReactInputMask
						mask="(999) 999 9999"
						maskChar="#"
						value={value}
						onChange={handleChange}
						placeholder="(###) ### ####"
					></ReactInputMask>
				</div>
			</div>
		</>
	);
}
