import { Check } from "react-feather";
import Button from "../components/Button";
import ModalContent from "./modalcontent/ModalContent";
import './ErrorMessage.scss';

function ErrorMessage({ message, title = 'Invalid Data', showConfirm = true, handleConfirmAcknowledgement }: any) {
	return (
		<ModalContent headerText={title}>
			<div className="error-message">
				<section className="sentence">{message}</section>
				{showConfirm && <Button
					label="Confirm"
					icon={<Check />}
					onClick={handleConfirmAcknowledgement}
				/>}
			</div>
		</ModalContent>
	);
}

export default ErrorMessage;
