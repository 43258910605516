import './FormHeader.scss';

function FormHeader({ text }: any) {
	return (
		<div className="form-header">
			<h2>{text}</h2>
		</div>
	);
}

export default FormHeader;
