import { useEffect, useState } from "react";
import { CheckSquare, Square } from "react-feather";

function CheckboxInput({ label, checked, onChecked }: any) {
	const [localChecked, setChecked] = useState(checked);

	useEffect(() => {
		setChecked(checked);
	}, [checked]);

	const ontoggle = (data: any) => {
		const newchecked = !localChecked;
		setChecked(newchecked);
		onChecked && onChecked(newchecked);
	}

	return (
		<>
			<div className="checkbox input-group">
				<div className="input-label">{label}</div>
				<div className="input">
					<input type="checkbox" />
					<button type="button" onClick={ontoggle}>
						{localChecked ? <CheckSquare size={24} /> : <Square size={24} />}
					</button>
				</div>
			</div>
		</>
	);
}

export default CheckboxInput;
