import './ModalContent.scss';

function ModalContent({ headerText, children }: any) {
	return (
		<>
			<div className="modal-content-wrap">
				<div className="modal-header">{headerText}</div>
				<div className="modal-inner-content">{children}</div>
			</div>
		</>
	);
}

export default ModalContent;
