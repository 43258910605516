import { useEffect, useState } from "react";
import { Check, Send } from "react-feather";
import { useLocation } from "react-router";
import Button from "../../components/Button";
import CheckboxInput from "../../components/CheckboxInput";
import Form from "../../components/Form";
import Modal from "../../components/Modal/Modal";
import ModalContainer from "../../components/Modal/ModalContainer";
import NumberInput from "../../components/NumberInput";
import PhoneInput from "../../components/PhoneInput";
import TextInput from "../../components/TextInput";
import DisclaimerMessage from "./DisclaimerMessage";
import ErrorMessage from "../../features/ErrorMessage";
import './SignIn.scss';
import { signIn } from "../../app/api";

enum WHICH_MODAL {
	DISCLAIMER,
	INVALID_CUSTOMER_INPUT,
	IS_INVALID_TABLE,
}

export function SignIn({ tableNum, onSignIn }: any) {
	const [submitted, setSubmitted] = useState(false);
	const [customerName, setCustomerName] = useState("");
	const [phone, setPhone] = useState("");
	const [personCount, setPersonCount] = useState(0);
	const [isGroup, setIsGroup] = useState(false);
	const [groupNames, setGroupNames] = useState<Array<string>>([]);
	const [showModal, setShowModal] = useState(false);
	const [modalType, setModalType] = useState(WHICH_MODAL.DISCLAIMER);
	const [tableNumber, setTableNumber] = useState(tableNum);
	const [loading, setLoading] = useState(false);

	const search = useLocation().search;

	const closeModal = () => {
		setShowModal(false);
	};

	const assignGroupnameValue = (name: string, idx: number) => {
		const d = [...groupNames];
		d[idx] = name;
		setGroupNames(d);
	};

	const handleGroupChange = (a: boolean) => {
		setIsGroup(a);
		setPersonCount(0);
	};

	const handlePersonCount = (a: number) => {
		setPersonCount(a);
		// person count minus main customer contact
		setGroupNames(Array.from({ length: a - 1 }, (i) => ""));
	};

	const handleConfirmAcknowledgement = () => {
		// do something
		closeModal();
	};

	const handleSubmit = async () => {

		setLoading(true);

		const data = {
			name: customerName,
			phone: phone,
			group: groupNames,
			isGroup: isGroup,
			personCount: personCount,
			tableNumber: tableNumber,
			submitted: false,
		};

		console.log(data);

		if (!data.phone || !data.name) {
			setModalType(WHICH_MODAL.INVALID_CUSTOMER_INPUT);
			setShowModal(true);
			setLoading(false);
			return;
		} else {
			setSubmitted(true);
			data.submitted = true;
			await signIn(data);
			onSignIn && onSignIn();
			setLoading(false);
		}
	};

	const selectModal = () => {
		switch (modalType) {
			case WHICH_MODAL.DISCLAIMER:
				return (
					<DisclaimerMessage
						handleConfirmAcknowledgement={
							handleConfirmAcknowledgement
						}
					/>
				);
			case WHICH_MODAL.INVALID_CUSTOMER_INPUT:
				return (
					<ErrorMessage
						message="Customer Name and Phone Number are required."
						handleConfirmAcknowledgement={
							handleConfirmAcknowledgement
						}
					/>
				);
			case WHICH_MODAL.IS_INVALID_TABLE:
				return (
					<ErrorMessage
						message="Invalid Dining Table. Please try again"
						handleConfirmAcknowledgement={
							handleConfirmAcknowledgement
						}
					/>
				);
			default:
				setModalType(WHICH_MODAL.DISCLAIMER);
				break;
		}
	};

	useEffect(() => {
		setShowModal(true);
	}, [search]);

	useEffect(() => {
		setTableNumber(tableNum);
	}, [tableNum]);

	return (
		<>
			<Form
				className="sign-in-form"
				header={`Genji Sushi North York COVID-19 Contact Tracing`}
			>
				{showModal && (
					<ModalContainer>
						<Modal onClick={closeModal} showCloseButton={false}>
							{selectModal()}
						</Modal>
					</ModalContainer>
				)}

				<fieldset>
					<h4 className="curr-table">
						You are sitting at Table # <span>{tableNumber}</span>
					</h4>
					<TextInput
						label="Customer First and Last Name"
						value={customerName}
						onChange={setCustomerName}
					/>
					<PhoneInput
						label="Contact Number"
						value={phone}
						onChange={setPhone}
					/>
					<div className="field-pair">
						<CheckboxInput
							label="Are you in a group?"
							checked={isGroup}
							onChecked={handleGroupChange}
						/>
						{isGroup && (
							<NumberInput
								label="How many of you are in the group?"
								value={personCount}
								onChange={handlePersonCount}
							/>
						)}
					</div>
					{groupNames.length > 0 && (
						<div>
							<div className="groupname-label">
								Enter the other {groupNames.length} person(s) in
								your group (optional)
							</div>
							{groupNames.map((v, idx) => (
								<TextInput
									key={idx.toString()}
									label="First and Last Name"
									value={groupNames[idx]}
									onChange={(a: string) =>
										assignGroupnameValue(a, idx)
									}
								/>
							))}
						</div>
					)}
				</fieldset>

				<div className="controls">
					<Button
						label="Submit"
						icon={submitted ? <Check /> : <Send />}
						onClick={handleSubmit}
					></Button>
				</div>
			</Form>
		</>
	);
}
